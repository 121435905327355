@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Times New Roman', serif;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
