.google-login-button {
  background-color: black; /* Filled black background */
  color: white;            /* White text color */
  border-radius: 50%;      /* Circular shape */
  width: 200px;            /* Button width */
  height: 200px;           /* Set height to match width for a perfect circle */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
}

.home {
  font-family: Luminari,fantasy;
}

.gear:focus {
  outline: none;
}

.scrolled::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.126);
}
.scrolled::-webkit-scrollbar {
  width: 10px;
}
.scrolled::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgb(121, 40, 0);
  background-color: rgb(121, 40, 0);
}

.home-scrolled::-webkit-scrollbar-track {
  background-color: rgb(121, 40, 0);
}

.home-scrolled {
  scrollbar-color:white black;
}

.home-scrolled::-webkit-scrollbar {
  width: 15px;
}
.home-scrolled::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgb(92, 31, 0);
  opacity: 0.5;
  background-color: rgb(92, 31, 0);
}

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select some files';
  color: black;
  display: inline-block;
  border: 2px solid black;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  font-size: bold;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  background-color: rgb(174, 174, 174);
  box-shadow: inset 0px 0px 5px black;
}
.custom-file-input:active {
  outline: 0;
}

.pinger {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1);
}

@keyframes fade {
  0% {
    opacity: calc(0);
  }
  20% {
    opacity: calc(0);
  }
  30% {
    opacity: calc(1);
  }
  95% {
    opacity: calc(1);
  }
  100% {
    opacity: calc(0);
  }
}

.fade {  
  opacity: calc(0);
  background-color: #000;
  animation: 8s ease-out 0s 1 fade;
}

@keyframes bubble {
  0% {
    transform:scale(1)
  }
  50% {
    transform:scale(1.2)
  }
  100% {
    transform:scale(1)
  }
}

.bubble {  
  animation: 1s 0s 3 bubble;
}

.welcome {
  text-shadow: 4px 4px #000;
}

.comment {
  white-space: initial;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateY(-7%);
    opacity: calc(0);
  }
  100% {
    transform: translateY(0);
    opacity: calc(1);
  }
}

.hider::-webkit-scrollbar {
  display:none;
}
.hider {
  scrollbar-width: none;
}

.header {  
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

@keyframes  become {
  0% {
    opacity: calc(0.5);
  }
  100% {
    opacity: calc(1);
  }
}

.now {  
  animation: 1s ease-out 0s 1 become;
}

.wood {
  background-image: url(../assets/Bulletin2.jpg);
  background-repeat: repeat-y;
  background-size: 100%;
}

.graywood {
  background-image: url(../assets/graybulletin.jpg);
  background-repeat: repeat-y;
  background-size: 100%;
}

.pin {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.pin:hover {
  -webkit-transform:scale(1.1);
  transform:scale(1.1);
}

.dropbtn {
    background-color: transparent;
    border: none;
    block-size: 25px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-family: Luminari,fantasy ;
  }
  
  .dropdown {
    display: inline-block;
  }
  
  .dropdowncontent {
    display: none;
    min-width: 64px;
  }
  
  .dropdowncontent a {
    text-decoration: none;
    display: block;
    block-size: 25px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    opacity: calc(1);
  }
  
  .dropdowncontent a:hover {
    -webkit-transform:scale(1.2);
    transform:scale(1.2);
    opacity: calc(0.9);
  }
  
  .show {display: block;}

  .dropbtn:hover {
    -webkit-transform:scale(1.3);
    transform:scale(1.3);
  }

  .ldropbtn {
    background-color: transparent;
    border: none;
    padding: 10px;
    opacity: calc(0.7);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-family: Luminari,fantasy ;
    opacity:calc(0.5);
  }
  
  .ldropdown {
    display: inline-block;
  }
  
  .ldropdowncontent {
    display: none;
    min-width: 64px;
  }
  
  .ldropdowncontent button {
    text-decoration: none;
    display: block;
    padding: 10px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    opacity: calc(0.7);
  }

  .theresize {
    resize: horizontal;
  }
  
  .ldropdowncontent button:hover {
    -webkit-transform:scale(1.2);
    transform:scale(1.2);
    opacity: calc(1);
  }

  .ldropdown:hover .ldropdowncontent {display:block}

  .ldropdown:hover {background-color:rgba(0,0,0,0.6)}

  .ldropbtn:hover {
    opacity:calc(1);
    -webkit-transform:scale(1.2);
    transform:scale(1.2);
  }

  .rainbow {
    font-family: Luminari,fantasy ;
    -webkit-animation:rainbow 50s infinite;
    -ms-animation:rainbow 50s infinite;
    -o-animation:rainbow 50s infinite;
    animation:rainbow 50s infinite;
    }
    @-webkit-keyframes rainbow {
      0% {color: #ff0000;}
      10% {color: #ffaa00;}
      15% {color: #f6ff00;}
      20% {color: #77ff00;}
      30% {color: #00ff80;}
      45% {color: #00ffff;}
      55% {color: #0080ff;}
      65% {color: #0059ff;}
      75% {color: #a600ff;}
      85% {color: #fb00ff;}
      100% {color: #ff0080;}
    }
    @-ms-keyframes rainbow {
      0% {color: #ff0000;}
      10% {color: #ffaa00;}
      15% {color: #f6ff00;}
      20% {color: #77ff00;}
      30% {color: #00ff80;}
      45% {color: #00ffff;}
      55% {color: #0080ff;}
      65% {color: #0059ff;}
      75% {color: #a600ff;}
      85% {color: #fb00ff;}
      100% {color: #ff0080;}
    }
    @-o-keyframes rainbow {
      0% {color: #ff0000;}
      10% {color: #ffaa00;}
      15% {color: #f6ff00;}
      20% {color: #77ff00;}
      30% {color: #00ff80;}
      45% {color: #00ffff;}
      55% {color: #0080ff;}
      65% {color: #0059ff;}
      75% {color: #a600ff;}
      85% {color: #fb00ff;}
      100% {color: #ff0080;}
    }
    @keyframes rainbow {
      0% {color: #ff0000;}
      10% {color: #ffaa00;}
      15% {color: #f6ff00;}
      20% {color: #77ff00;}
      30% {color: #00ff80;}
      45% {color: #00ffff;}
      55% {color: #0080ff;}
      65% {color: #0059ff;}
      75% {color: #a600ff;}
      85% {color: #fb00ff;}
      100% {color: #ff0080;}
    }

  .rainbow:hover {
    -webkit-animation:rainbow2 6s infinite;
    -ms-animation:rainbow2 6s infinite;
    -o-animation:rainbow2 6s infinite;
    animation:rainbow2 6s infinite;
    }
    @-webkit-keyframes rainbow2 {
      0% {color: #ff0000;transform:scale(1);}
      10% {color: #ffaa00;transform:scale(1.4);}
      15% {color: #f6ff00;}
      20% {color: #77ff00;transform:scale(1)}
      30% {color: #00ff80;transform:scale(1.4);}
      40% {transform:scale(1)}
      45% {color: #00ffff;}
      50% {transform:scale(1.5)}
      55% {color: #0080ff;}
      60% {transform:scale(1)}
      65% {color: #0059ff;}
      70% {transform:scale(1.5)}
      75% {color: #a600ff;}
      80% {transform:scale(1)}
      85% {color: #fb00ff;}
      90% {transform:scale(1.6)}
      100% {color: #ff0080;transform:scale(1);}
    }
    @-ms-keyframes rainbow2 {
      0% {color: #ff0000;transform:scale(1);}
      10% {color: #ffaa00;transform:scale(1.4);}
      15% {color: #f6ff00;}
      20% {color: #77ff00;transform:scale(1)}
      30% {color: #00ff80;transform:scale(1.4);}
      40% {transform:scale(1)}
      45% {color: #00ffff;}
      50% {transform:scale(1.5)}
      55% {color: #0080ff;}
      60% {transform:scale(1)}
      65% {color: #0059ff;}
      70% {transform:scale(1.5)}
      75% {color: #a600ff;}
      80% {transform:scale(1)}
      85% {color: #fb00ff;}
      90% {transform:scale(1.6)}
      100% {color: #ff0080;transform:scale(1);}
    }
    @-o-keyframes rainbow2 {
      0% {color: #ff0000;transform:scale(1);}
      10% {color: #ffaa00;transform:scale(1.4);}
      15% {color: #f6ff00;}
      20% {color: #77ff00;transform:scale(1)}
      30% {color: #00ff80;transform:scale(1.4);}
      40% {transform:scale(1)}
      45% {color: #00ffff;}
      50% {transform:scale(1.5)}
      55% {color: #0080ff;}
      60% {transform:scale(1)}
      65% {color: #0059ff;}
      70% {transform:scale(1.5)}
      75% {color: #a600ff;}
      80% {transform:scale(1)}
      85% {color: #fb00ff;}
      90% {transform:scale(1.6)}
      100% {color: #ff0080;transform:scale(1);}
    }
    @keyframes rainbow2 {
      0% {color: #ff0000;transform:scale(1);}
      10% {color: #ffaa00;transform:scale(1.4);}
      15% {color: #f6ff00;}
      20% {color: #77ff00;transform:scale(1)}
      30% {color: #00ff80;transform:scale(1.4);}
      40% {transform:scale(1)}
      45% {color: #00ffff;}
      50% {transform:scale(1.5)}
      55% {color: #0080ff;}
      60% {transform:scale(1)}
      65% {color: #0059ff;}
      70% {transform:scale(1.5)}
      75% {color: #a600ff;}
      80% {transform:scale(1)}
      85% {color: #fb00ff;}
      90% {transform:scale(1.6)}
      100% {color: #ff0080;transform:scale(1);}
    }

    .inspiration {
      font-family: Luminari,fantasy ;
      -webkit-animation:inspiration 10s infinite;
      -ms-animation:inspiration 10s infinite;
      -o-animation:inspiration 10s infinite;
      animation:inspiration 10s infinite;
      }
      @-webkit-keyframes inspiration {
        0% {color: #f3ce60;}
        25% {color: #EAB39D;}
        50% {color: #f3ce60;}
        75% {color: #EAB39D;}
        100% {color: #f3ce60;}
      }
      @-ms-keyframes inspiration {
        0% {color: #f3ce60;}
        25% {color: #EAB39D;}
        50% {color: #f3ce60;}
        75% {color: #EAB39D;}
        100% {color: #f3ce60;}
      }
      @-o-keyframes inspiration {
        0% {color: #f3ce60;}
        25% {color: #EAB39D;}
        50% {color: #f3ce60;}
        75% {color: #EAB39D;}
        100% {color: #f3ce60;}
      }
      @keyframes inspiration {
        0% {color: #f3ce60;}
        25% {color: #EAB39D;}
        50% {color: #f3ce60;}
        75% {color: #EAB39D;}
        100% {color: #f3ce60;}
      }
  
    .inspiration:hover {
      -webkit-animation:inspiration2 3s infinite;
      -ms-animation:inspiration2 3s infinite;
      -o-animation:inspiration2 3s infinite;
      animation:inspiration2 3s infinite;
      }
      @-webkit-keyframes inspiration2 {
        0% {color: #ffb700;transform:scale(1);}
        25% {color: #ffd15e;transform:scale(1.4);}
        50% {color: #ffb700;transform:scale(1);}
        75% {color: #ffd978;transform:scale(1.4);}
        100% {color: #ffb700;transform:scale(1);}
      }
      @-ms-keyframes inspiration2 {
        0% {color: #ffb700;transform:scale(1);}
        25% {color: #ffd15e;transform:scale(1.4);}
        50% {color: #ffb700;transform:scale(1);}
        75% {color: #ffd978;transform:scale(1.4);}
        100% {color: #ffb700;transform:scale(1);}
      }
      @-o-keyframes inspiration2 {
        0% {color: #ffb700;transform:scale(1);}
      25% {color: #ffd15e;transform:scale(1.4);}
      50% {color: #ffb700;transform:scale(1);}
      75% {color: #ffd978;transform:scale(1.4);}
      100% {color: #ffb700;transform:scale(1);}
      }
      @keyframes inspiration2 {
        0% {color: #ffb700;transform:scale(1);}
      25% {color: #ffd15e;transform:scale(1.4);}
      50% {color: #ffb700;transform:scale(1);}
      75% {color: #ffd978;transform:scale(1.4);}
      100% {color: #ffb700;transform:scale(1);}
      }